<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Санал болгох меню
      </h3>
      <div class="action-section">
        <el-button type="primary" @click="addNew">Үүсгэх</el-button>
      </div>
    </div>
    <el-row :gutter="0">
      <el-col :span="24" :offset="0">
        <div class="panel payments-container">
          <div class="column" align="left">
            <el-form label-position="top" :inline="true" size="mini">
              <el-form-item>
                <el-input
                  style="width: 200px;"
                  prefix-icon="el-icon-search"
                  v-model="search"
                  size="mini"
                  placeholder="Хайх суртчилгааны нэр"
                />
              </el-form-item>
              <el-form-item>
                <el-select
                  placeholder="Үйлчилгээ"
                  v-model="serviceChoose"
                  @change="onChangeService(serviceChoose, status)"
                >
                  <el-option value="" key="all" label="Бүгд"></el-option>
                  <el-option
                    v-for="(service, index) in servicesList"
                    :label="service.name_mon"
                    :value="service.name_eng"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <div class="column" align="right">
            <el-select
              class="mr5"
              placeholder="Төлөв"
              size="mini"
              v-model="status"
              @change="getSuggest(serviceChoose, status)"
              clearable
            >
              <el-option value="true" key="1" label="Идэвхтэй"></el-option>
              <el-option value="false" key="0" label="Идэвхгүй"></el-option>
            </el-select>
          </div>
          <el-table
            :data="
              suggestList.filter(
                data =>
                  !search ||
                  data.name_mon.toLowerCase().includes(search.toLowerCase())
              )
            "
            size="mini"
            style="width: 95%,margin: 100vh;"
          >
            <el-table-column type="index" width="50"> </el-table-column>
            <el-table-column
              prop="name_eng"
              label="Англи нэр"
            ></el-table-column>
            <el-table-column
              prop="name_mon"
              label="Монгол нэр"
            ></el-table-column>
            <el-table-column prop="service" label="Сервис"></el-table-column>
            <el-table-column prop="dayOfName" label="Гариг">
              <template slot-scope="scope">
                <el-tag
                  size="mini"
                  v-for="(day, dayIndex) in scope.row.dayOfName"
                  :key="dayIndex"
                >
                  {{ day }}
                </el-tag>
              </template>
            </el-table-column>
            <el-table-column
              prop="start_date"
              label="Эхлэх өдөр"
            ></el-table-column>
            <el-table-column
              prop="end_date"
              label="Дуусах өдөр"
            ></el-table-column>
            <el-table-column
              prop="start_time"
              label="Эхлэх цаг"
            ></el-table-column>
            <el-table-column
              prop="end_time"
              label="Дуусах цаг"
            ></el-table-column>
            <el-table-column prop="is_show" label="Засах">
              <template slot-scope="scope">
                <el-button
                  type="success"
                  size="mini"
                  icon="el-icon-edit"
                  style="margin-top: 10px;"
                  @click="edit(scope.row)"
                  >Засах</el-button
                >
              </template>
            </el-table-column>
            <el-table-column prop="is_show" label="Устгах">
              <template slot-scope="scope">
                <el-button
                  type="danger"
                  size="mini"
                  icon="el-icon-edit"
                  @click="deleteSuggest(scope.row.id)"
                  >Устгах</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-col>
    </el-row>
    <el-dialog
      v-if="selSuggestType"
      :title="selSuggestType.id > 0 ? 'Засах' : 'Үүсгэх'"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <el-form
        :inline="true"
        ref="ruleForm"
        :model="selSuggestType"
        :rules="rules"
        class="demo-form-inline"
        label-width="150px"
      >
        <el-form-item label="Монгол нэр:" prop="name_mon">
          <el-input
            v-model="selSuggestType.name_mon"
            placeholder="Монгол нэрээ оруулна  уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Англи нэр:" prop="name_eng">
          <el-input
            v-model="selSuggestType.name_eng"
            placeholder="Англи нэрээ оруулна уу?"
          >
          </el-input>
        </el-form-item>
        <el-form-item label="Гариг:" prop="dayOfName">
          <el-select
            v-model="selSuggestType.dayOfName"
            @change="$forceUpdate()"
            multiple
            placeholder="Англи нэрээ оруулна уу?"
          >
            <el-option label="Даваа" value="Mon"> </el-option>
            <el-option label="Мягмар" value="Tue"> </el-option>
            <el-option label="Лхагва" value="Wed"> </el-option>
            <el-option label="Пүрэв" value="Thu"> </el-option>
            <el-option label="Баасан" value="Fri"> </el-option>
            <el-option label="Бямба" value="Sat"> </el-option>
            <el-option label="Ням" value="Sun"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Эхлэх өдөр:" prop="start_date">
          <el-date-picker
            v-model="selSuggestType.start_date"
            type="date"
            @blur="$forceUpdate()"
            placeholder="Эхлэх өдрөө сонгоно уу?"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Дуусах өдөр:" prop="end_date">
          <el-date-picker
            v-model="selSuggestType.end_date"
            type="date"
            @blur="$forceUpdate()"
            placeholder="Дуусах өдрөө сонгоно уу?"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="Эхлэх цаг:" prop="start_time">
          <el-time-select
            v-model="selSuggestType.start_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            @blur="$forceUpdate()"
            placeholder="Эхлэх цагаа сонгоно уу?"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="Дуусах цаг:" prop="end_time">
          <el-time-select
            v-model="selSuggestType.end_time"
            :picker-options="{
              start: '00:00',
              step: '00:30',
              end: '23:30'
            }"
            @blur="$forceUpdate()"
            placeholder="Дуусах цагаа сонгоно уу?"
          >
          </el-time-select>
        </el-form-item>
        <el-form-item label="Категори">
          <el-select placeholder="Үйлчилгээ" v-model="selSuggestType.service">
            <el-option
              v-for="service in servicesList"
              :label="service.name_mon"
              :value="service.name_eng"
              :key="service.name_eng"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="Эрэмбэ:" prop="sort">
          <el-input-number
            v-model="selSuggestType.sort"
            active-text="Идэвхитэй"
            @change="$forceUpdate()"
            inactive-text="Нуусан"
          >
          </el-input-number>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="success" @click="createOrUpdate">{{
          selSuggestType.id > 0 ? "Засах" : "Үүсгэх"
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import service from "../helpers/services";
export default {
  created() {
    this.getServices();
    this.getSuggest(this.serviceChoose, this.status);
  },
  data() {
    return {
      activeSuggestTypes: [],
      selSuggestType: {},
      dialogVisible: false,
      rules: {
        name_mon: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        name_eng: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        dayOfName: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        start_date: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        end_date: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        start_time: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        end_time: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "blur"
          }
        ],
        is_show: [
          {
            required: true,
            message: "Хоосон байна",
            trigger: "change"
          }
        ]
      },
      suggestTypes: [],
      servicesList: [],
      suggestList: [],
      search: "",
      value: "",
      value1: true,
      value2: true,
      allServices: [],
      serviceChoose: "",
      status: true,
      search_menu: ""
    };
  },
  methods: {
    edit(item) {
      this.selSuggestType = item;
      if (this.$refs.ruleForm) this.$refs.ruleForm.clearValidate();
      this.dialogVisible = true;
    },
    createOrUpdate() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          if (this.selSuggestType.start_date.length !== 10) {
            const date = new Date(this.selSuggestType.start_date);
            this.selSuggestType.start_date = new Date(
              date.toString().split("GMT")[0] + " UTC"
            )
              .toISOString()
              .slice(0, 10)
              .replace("T", " ");
          }
          if (this.selSuggestType.end_date.length !== 10) {
            const date = new Date(this.selSuggestType.end_date);
            this.selSuggestType.end_date = new Date(
              date.toString().split("GMT")[0] + " UTC"
            )
              .toISOString()
              .slice(0, 10)
              .replace("T", " ");
          }
          this.selSuggestType.end_date.length;
          if (this.selSuggestType.id > 0) {
            service
              .updateSuggestMenu(this.selSuggestType, this.selSuggestType.id)
              .then(res => {
                if (res.data.status === "success") {
                  this.dialogVisible = false;
                  this.$notify({
                    title: "Амжилттай",
                    message: res.message,
                    type: "success"
                  });
                  this.getSuggest(this.serviceChoose, this.status);
                } else {
                  this.$notify({
                    title: "Алдаа гарлаа",
                    message: res.message,
                    type: "warning"
                  });
                }
              });
          } else {
            service.createSuggestMenu(this.selSuggestType).then(res => {
              if (res.data.status === "success") {
                this.dialogVisible = false;
                this.$notify({
                  title: "Амжилттай",
                  message: res.message,
                  type: "success"
                });
                this.getSuggest(this.serviceChoose, this.status);
              } else {
                this.$notify({
                  title: "Алдаа гарлаа",
                  message: res.message,
                  type: "warning"
                });
              }
            });
          }
        } else {
          this.$notify({
            title: "Алдаа",
            message: "Зарим талбарыг бөглөөгүй байна.",
            type: "warning"
          });
          return false;
        }
      });
    },
    addNew() {
      const date = new Date();
      const formattedDate = new Date(date.toString().split("GMT")[0] + " UTC")
        .toISOString()
        .slice(0, 10)
        .replace("T", " ");
      date.setMonth(date.getMonth() + 6);
      const formattedDate1 = new Date(date.toString().split("GMT")[0] + " UTC")
        .toISOString()
        .slice(0, 10)
        .replace("T", " ");
      this.selSuggestType = {};
      this.selSuggestType.start_date = formattedDate;
      this.selSuggestType.end_date = formattedDate1;
      this.selSuggestType.start_time = "10:00:00";
      this.selSuggestType.end_time = "22:00:00";
      this.selSuggestType.is_show = true;
      this.selSuggestType.dayOfName = [
        "Mon",
        "Tue",
        "Wed",
        "Thu",
        "Fri",
        "Sat",
        "Sun"
      ];
      this.dialogVisible = true;
    },
    async getSuggest(serviceChoose, is_show) {
      service.getAllSuggestMenus(serviceChoose, is_show).then(res => {
        this.suggestList = res.data.data;
        this.suggestTypes = res.data.data;
        this.suggestTypes.forEach((element, index) => {
          element.isAppShow = false;
          res.forEach(el => {
            if (element.id === el.id || element.isAppShow) {
              this.suggestTypes[index].isAppShow = true;
            }
          });
        });
        this.$forceUpdate();
      });
    },
    deleteSuggest(id) {
      this.$confirm(
        "This will permanently delete the file. Continue?",
        "Анхааруулга",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      )
        .then(() => {
          service.deleteSuggestMenu(id).then(res => {
            if (res.data.status === "success") {
              this.dialogVisible = false;
              this.$notify({
                title: "Амжилттай",
                message: res.message,
                type: "success"
              });
              this.getSuggest(this.serviceChoose, this.status);
            } else {
              this.$notify({
                title: "Алдаа гарлаа",
                dangerouslyUseHTMLString: true,
                message: res.message + "<br/>" + "<li>" + res.product + "</li>",
                type: "warning"
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "Delete canceled"
          });
        });
    },
    getServices() {
      service.getServices().then(servicesResponse => {
        let b = servicesResponse.data.data.map(el => {
          el.name_eng = el.name_eng.toLowerCase();
          el.name_mon = el.name_mon.toLowerCase();
          return el;
        });
        this.servicesList = b;
      });
    },
    onChangeService(serviceChoose, status) {
      this.getSuggest(serviceChoose, status);
    }
  }
};
</script>
<style>
.time {
  font-size: 13px;
  color: #777;
  margin-bottom: 15px;
}
.time1 {
  font-size: 10px;
  color: #999;
  margin-bottom: 15px;
}
.tag {
  float: left;
  margin-top: 5px;
}

.bottom {
  margin-top: 13px;
  line-height: 12px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}

.column {
  width: 50%;
  float: left;
}
</style>
